export const siteData = {
  "name": "DOUDIA",
  "address": "6 RUE D'AUVERGNE,",
  "postCode": "78690",
  "city": "les",
  "phone": "0165505776",
  "url": "https://doudia.store",
  "urlSub": "doudia.store",
  "price": "price_1Q5463AtlcZoCW4jtbRg38Oo",
  "pk": "pk_live_51Q53wWAtlcZoCW4jf1QpaRsBFj6doPA2utEw12OWw7JyCl0OrT6jwQUcMmwrWe3zbs45uuhuggc2uydyolPTQYRK00xjwgVj2c",
  "sk": "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"
};